const environments = Object.freeze({
  development: 'development',
  production: 'production',
});

const config = Object.freeze({
  [environments.development]: {
    rootUrl: 'http://localhost:8000',
    apiUrl: 'http://localhost:5000',
    newsUrl: 'https://namernews.main.dev.kube.namebase.io',
    shopUrl: 'https://shop.namebase.io/',
    learnUrl: 'https://learn.namebase.io',
    discordUrl: 'https://discord.com/invite/V3aTrkp',
    apiDocsUrl: 'https://github.com/namebasehq/api-documentation',
    showcaseUrl: 'https://namernews.main.dev.kube.namebase.io/showcase',
    feedbackUrl: 'https://feedback.namebase.io',
    communityUrl: 'https://community.namebase.io',
    joinCommunityUrl: 'https://community.namebase.io/join',
    aboutHandshakeLearnUrl: 'https://learn.namebase.io/about-handshake',
  },
  [environments.production]: {
    rootUrl: 'https://develop.www.namebase.io',
    apiUrl: 'https://staging-app.namebase.io',
    newsUrl: 'https://news.namebase.io',
    shopUrl: 'https://shop.namebase.io/',
    learnUrl: 'https://learn.namebase.io',
    discordUrl: 'https://discord.com/invite/V3aTrkp',
    apiDocsUrl: 'https://github.com/namebasehq/api-documentation',
    showcaseUrl: 'https://news.namebase.io/showcase',
    feedbackUrl: 'https://feedback.namebase.io',
    communityUrl: 'https://community.namebase.io',
    joinCommunityUrl: 'https://community.namebase.io/join',
    aboutHandshakeLearnUrl: 'https://learn.namebase.io/about-handshake',
  },
});

const isValidEnvironment = process.env.NODE_ENV in config;
const environment = isValidEnvironment ? process.env.NODE_ENV : environments.production;

module.exports = config[environment];
