import React from 'react';

import { SocialIconWrapper } from './SocialIconWrapper';
import { theme } from '../../../theme';

export const TwitterSocialIcon = props => (
  <SocialIconWrapper {...props} hoverColor={theme.color.twitterBlue}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0F2E64" />
      <path
        d="M17.422 26.46C22.744 26.46 25.654 22.05 25.654 18.228C25.654 18.102 25.654 17.976 25.648 17.856C26.212 17.448 26.704 16.938 27.094 16.356C26.578 16.584 26.02 16.74 25.432 16.812C26.032 16.452 26.488 15.888 26.704 15.21C26.146 15.54 25.528 15.78 24.868 15.912C24.34 15.348 23.59 15 22.756 15C21.16 15 19.864 16.296 19.864 17.892C19.864 18.12 19.888 18.342 19.942 18.552C17.536 18.432 15.406 17.28 13.978 15.528C13.732 15.954 13.588 16.452 13.588 16.98C13.588 17.982 14.098 18.87 14.878 19.386C14.404 19.374 13.96 19.242 13.57 19.026C13.57 19.038 13.57 19.05 13.57 19.062C13.57 20.466 14.566 21.63 15.892 21.9C15.652 21.966 15.394 22.002 15.13 22.002C14.944 22.002 14.764 21.984 14.584 21.948C14.95 23.1 16.018 23.934 17.284 23.958C16.294 24.732 15.046 25.194 13.69 25.194C13.456 25.194 13.228 25.182 13 25.152C14.266 25.98 15.79 26.46 17.422 26.46Z"
        fill="white"
      />
    </svg>
  </SocialIconWrapper>
);
