import React from 'react';

import { SocialIconWrapper } from './SocialIconWrapper';
import { theme } from '../../../theme';

export const NomicsSocialIcon = props => (
  <SocialIconWrapper {...props} hoverColor={theme.color.nomicsPurple}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0F2E64" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.6999L12.0002 15.666V15.5H12.0053C12.1092 13.5495 13.7236 12 15.6999 12C17.6613 12 19.2663 13.5262 19.392 15.4558L21.395 13.4083C22.0726 12.5505 23.122 12 24.3 12C26.2763 12 27.8907 13.5495 27.9946 15.5H27.9994V15.6397C27.9998 15.6597 27.9999 15.6798 27.9999 15.7C27.9999 15.7201 27.9998 15.7402 27.9994 15.7602V24.266L27.9996 24.3015L27.9994 24.3371V24.5998H27.9878C27.8359 26.5038 26.2427 28.0015 24.2997 28.0015C22.3383 28.0015 20.7334 26.4753 20.6077 24.5458L18.6096 26.5872C17.9322 27.4484 16.8807 28.0015 15.6999 28.0015C13.7569 28.0015 12.1638 26.5037 12.0119 24.5998H12.0002V24.3355L12 24.3015L12.0002 24.2676V15.7339L12 15.6999ZM20.0999 23.7012C22.1433 23.7012 23.7998 22.0447 23.7998 20.0012C23.7998 17.9578 22.1433 16.3013 20.0999 16.3013C18.0564 16.3013 16.3999 17.9578 16.3999 20.0012C16.3999 22.0447 18.0564 23.7012 20.0999 23.7012Z"
        fill="white"
      />
    </svg>
  </SocialIconWrapper>
);
