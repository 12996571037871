import config from '../../config';

export const linkTypes = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  APP: 'APP',
};

const socialsMeta = {
  target: '_blank',
  rel: 'noreferrer',
};

export const socials = {
  discord: {
    title: 'Namebase Discord',
    href: config.discordUrl,
    ...socialsMeta,
  },
  twitter: {
    title: 'Namebase Twitter',
    href: 'https://twitter.com/namebasehq',
    ...socialsMeta,
  },
  coinGecko: {
    title: 'Namebase on CoinGecko',
    href: 'https://www.coingecko.com/en/exchanges/namebase',
    ...socialsMeta,
  },
  nomics: {
    title: 'Namebase on Nomics',
    href: 'https://nomics.com/exchanges/namebase',
    ...socialsMeta,
  },
};

export const sections = [
  {
    header: 'Learn',
    links: [
      { text: 'Learning Center', path: config.learnUrl, type: linkTypes.EXTERNAL },
      { text: 'FAQ', path: '/faq', type: linkTypes.INTERNAL },
      { text: 'Stats', path: '/stats', type: linkTypes.INTERNAL },
      { text: 'Handshake Whitepaper', path: '/handshake-whitepaper', type: linkTypes.INTERNAL },
    ],
  },
  {
    header: 'Company',
    links: [
      { text: 'About', path: '/about', type: linkTypes.INTERNAL },
      { text: 'Blog', path: '/blog', type: linkTypes.INTERNAL },
      { text: 'Privacy Policy', path: '/privacypolicy', type: linkTypes.INTERNAL },
      { text: 'Terms of Service', path: '/termsofuse', type: linkTypes.INTERNAL },
    ],
  },
  {
    header: 'Product',
    links: [
      { text: 'Buy / Sell Handshake', path: '/buy', type: linkTypes.APP },
      { text: 'Handshake domains', path: '/domains', type: linkTypes.APP },
      { text: 'Wrapped HNS', path: '/wrapped-hns', type: linkTypes.INTERNAL },
      { text: 'Claim airdrop', path: '/airdrop', type: linkTypes.EXTERNAL },
      { text: 'Leave feedback', path: config.feedbackUrl, type: linkTypes.EXTERNAL },
      { text: 'HDNS.io', path: 'https://hdns.io', type: linkTypes.EXTERNAL },
      { text: 'API', path: config.apiDocsUrl, type: linkTypes.EXTERNAL },
    ],
  },
  {
    header: 'Community',
    links: [
      { text: 'Namer News', path: config.newsUrl, type: linkTypes.EXTERNAL },
      { text: 'Community', path: config.communityUrl, type: linkTypes.EXTERNAL },
      { text: 'Join the effort', path: config.joinCommunityUrl, type: linkTypes.EXTERNAL },
      { text: 'Merch', path: config.shopUrl, type: linkTypes.EXTERNAL },
    ],
  },
];
