import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { events } from '../../utils/trackEvent';
import { sections, linkTypes, socials } from './footerData';

import {
  FooterRoot,
  FooterNav,
  LinksWrapper,
  LinksGroup,
  Link,
  SocialsWrapper,
  CopywriteNamebase,
  LinksGroupHeader,
  ScrollToTopButton,
} from './Footer.StyledComponents';

import {
  CoinGeckoSocialIcon,
  DiscordSocialIcon,
  NamebaseLogoSvg,
  NomicsSocialIcon,
  ScrollToTopArrow,
  TwitterSocialIcon,
} from './components';

const Footer = () => (
  <FooterRoot>
    <NamebaseLogoSvg />
    <FooterNav>
      <LinksWrapper>
        {sections.map(section => (
          <LinksGroup key={section.header}>
            <LinksGroupHeader children={section.header} />
            {section.links.map(({ type, text, path }) => (
              <Link
                key={path}
                as={type === linkTypes.INTERNAL ? GatsbyLink : 'a'}
                target={type === linkTypes.INTERNAL ? null : '_blank'}
                rel="noreferrer"
                children={text}
                href={path}
                to={path}
                onClick={() => events.footer.link.send(text)}
              />
            ))}
          </LinksGroup>
        ))}
      </LinksWrapper>
      <SocialsWrapper>
        <div>
          <span css={{ marginRight: '16px' }}>
            <TwitterSocialIcon {...socials.twitter} />
          </span>
          <span css={{ marginRight: '16px' }}>
            <DiscordSocialIcon {...socials.discord} />
          </span>
          <span css={{ marginRight: '16px' }}>
            <CoinGeckoSocialIcon {...socials.coinGecko} />
          </span>
          <span css={{ marginRight: '16px' }}>
            <NomicsSocialIcon {...socials.nomics} />
          </span>
        </div>
        <ScrollToTopButton
          aria-label={'Scroll to top'}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <ScrollToTopArrow />
        </ScrollToTopButton>
      </SocialsWrapper>
    </FooterNav>
    <CopywriteNamebase>{`©${new Date().getFullYear()} Namebase, Inc | Banking services are provided by Synapse's bank partners, Members FDIC`}</CopywriteNamebase>
  </FooterRoot>
);

export { Footer };
